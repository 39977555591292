<template>
    <div v-if="loaded">
        <wd-cell-group class="mys-reserve-act-content-form" style="margin-top: 32px;" title="新增预约" border>
            <wd-calendar v-model="addData.date" required label="日期选择" label-width="90px" @confirm="handleDateConfirm" />
            <wd-select-picker title="时间范围" required label="时间" type="radio" label-width="90px" v-model="addData.time" :columns="availableTime" :before-confirm="handleTimeConfirm"></wd-select-picker>
            <div class="wd-cell__wrapper" style="margin-left: 12px">
                <wd-notice-bar
                    v-if="!addData.date || addData.time.length < 1"
                    style="width: 100%"
                    text="请选择预约日期及时间段"
                    prefix="warn-bold"
                ></wd-notice-bar>
                <wd-notice-bar
                    v-else-if="!isDateChecked"
                    style="width: 100%"
                    text="正在检查当前时段是否可预约"
                    prefix="warn-bold"
                ></wd-notice-bar>
                <wd-notice-bar
                    v-else-if="isDateAvailable"
                    style="width: 100%"
                    text="当前时间段可预约"
                    prefix="check-outline"
                    color="#34D19D"
                    background-color="#f0f9eb"
                ></wd-notice-bar>
                <wd-notice-bar
                    v-else-if="!isDateAvailable"
                    style="width: 100%"
                    text="当前时间段不可预约"
                    prefix="warn-bold"
                    type="danger"
                ></wd-notice-bar>
            </div>
            <wd-input label="带队人姓名" required label-width="90px" v-model="addData.name" />
            <wd-input label="所属单位" required label-width="90px" placeholder="团体或组织名称" v-model="addData.group_name" />
            <wd-input label="电话" required label-width="90px" v-model="addData.phone" />
            <wd-input label="身份证号" required label-width="90px" v-model="addData.id_code" />
            <wd-cell title="团体人数" required title-width="90px">
                <div style="text-align: left;">
                    <wd-input-number v-model="addData.people_count" :max="maxCount"></wd-input-number>
                </div>
            </wd-cell>
            <div class="wd-cell__wrapper" style="margin-left: 12px">
                <wd-checkbox v-model="addData.agree" shape="square">已阅读并同意《预约规则》</wd-checkbox>
            </div>
            <!-- <wd-input label="说明" required label-width="90px" placeholder="输入场地使用部门、使用用途等内容" type="textarea" v-model="addData.description" show-word-limit rows="5" /> -->
        </wd-cell-group>
        <div class="mys-reserve-act-button">
            <wd-button :disabled="loading" @click="submitReservation()">提交预约</wd-button>
        </div>
        <!-- <wd-message-box :show="noticeDialog" @action="noticeDialog = false" title="预约规则">
            <div class="mys-reserve-description-content-text">
                <span style="color: #fa4350;">请在仔细阅读以下内容后使用本服务：</span>
                <br>1.测试测试测试
            </div>
        </wd-message-box> -->
    </div>
</template>

<script>
import axios from "axios";
import Common from "@/components/Common";
var apiurl = Common.APIurl;

export default {
    data: () => ({
        loaded: true,
        loading: false,
        isDateChecked: false,
        isDateAvailable: false,
        maxCount: 300,
        addData: {
            time: null,
        },
        timeColumns: [
            {
                value: 1,
                label: '9:30-11:30'
            },
            {
                value: 2,
                label: '14:00-17:00'
            },
        ],
        resultData: {},
        noticeDialog: true,
    }),
    methods: {
        submitReservation() {
            this.loading = true;
            if (this.addData.date == null) {
                this.$toast.error('请选择预约日期');
                this.loading = false;
            } else if (this.addData.agree == null || !this.addData.agree) {
                this.$toast.error('请先阅读并同意《预约规则》');
                this.loading = false;
            // } else if (this.addData.time == null) {
            //     this.$toast.error('请选择预约时间');
            //     this.loading = false;
            } else {
                axios
                    .get(apiurl + "Reservation/SubmitReservation", {
                        params: {
                            name: this.addData.name,
                            group_name: this.addData.group_name,
                            date: this.dateToString(this.addData.date),
                            time: this.addData.time,
                            phone: this.addData.phone,
                            id_code: this.addData.id_code,
                            people_count: this.addData.people_count,
                            type: 'group'
                        },
                    })
                    .then((response) => {
                        if (response.data.code != 0) {
                            this.$toast.error(response.data.message);
                            this.loading = false;
                        } else {
                            this.$toast.success(response.data.message);
                            this.loading = false;
                            this.addData = {
                                time: null,
                            }
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(error.message);
                        this.loading = false;
                    });
            }
        },
        handleDateConfirm() {
            this.addData.time = [];
        },
        handleTimeConfirm(value, resolve) {
            this.isDateChecked = false;
            if (this.addData.date && value != 0) {
                this.$toast.info('正在检查当前时段是否可预约');
                resolve(true);
                axios
                    .get(apiurl + "Reservation/TimeStatusCheck", {
                        params: {
                            date: this.dateToString(this.addData.date),
                            time: this.addData.time,
                        },
                    })
                    .then((response) => {
                        if (response.data.code != 0) {
                            this.$toast.error(response.data.message);
                        } else {
                            this.isDateAvailable = response.data.data.available;
                            this.maxCount = response.data.data.left;
                        }
                        this.isDateChecked = true;
                    })
                    .catch((error) => {
                        this.$toast.error(error.message);
                    });
            }
            resolve(true);
        },
        dateToString(date) {
            var year = date.getFullYear();
            var month =(date.getMonth() + 1).toString();
            var day = (date.getDate()).toString();
            if (month.length == 1) {
              month = "0" + month;
            }
            if (day.length == 1) {
              day = "0" + day;
            }
            var dateTime = year + "-" + month + "-" + day;
            return dateTime;
        },
    },
    // created() {
    //     axios
    //         .get(apiurl + "UserCenter/ProfileCurrent")
    //         .then((response) => {
    //             var code = response.data.code;
    //             if (code == -1) {
    //                 this.$router.push({ path: "/User/Login/" });
    //             } else {
    //                 this.userData = response.data.data;
    //                 this.loaded = true;
    //             }
    //         })
    //         .catch((error) => {
    //             this.$toast.error(error.message);
    //         });
    // },
    computed: {
        availableTime() {
            var availableTime = this.timeColumns;
            if (this.addData.date) {
                var selectDate = this.dateToString(this.addData.date);
                var day = new Date(selectDate).getDay(); 
                if (day == 0 || day == 6) {
                    availableTime[0].disabled = true;
                    availableTime[1].disabled = true;
                    this.$toast.error('节假日暂不可预约');
                } else {
                    availableTime[0].disabled = false;
                    availableTime[1].disabled = false;
                }
            }
            return availableTime;
        },
    },
};
</script>

<style>
.mys-reserve-act-large-card {
    margin-top: -128px;
    margin-left: 16px;
    margin-right: 16px;
    padding: 14px;
    min-height: 64px;
    text-align: center;
    background: #fff;
    border-radius: 8px;
}
.mys-reserve-act-large-card .title {
    font-size: 28px;
    font-weight: 600;
}
.mys-reserve-act-large-card .subtitle {
    text-transform: uppercase;
    font-size: 12px;
}
.mys-reserve-act-large-card .button {
    margin-top: 6px;
}
.mys-reserve-act-content-form {
    margin: 16px;
    border-radius: 8px;
}
.mys-reserve-act-button {
    text-align: center;
}
.mys-reserve-description-content-text {
    margin-top: 2px;
    max-height: 260px;
    overflow-y: auto;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
}

.wd-cell {
    background: transparent;
}
.wd-cell-group__body {
    background: transparent;
}
.wd-cell-group__title {
    background: transparent;
}
</style>